import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {FaBars, FaTimes} from 'react-icons/fa';
import logo from '../../src/IMTI-logo copy.png';
import { click } from '@testing-library/user-event/dist/click';
import {Button} from '../Button';
import './Navbar.css';
import {IconContext} from 'react-icons/lib';

function Navbar() {
    const [click, setClick] = useState(false);

    const [button, setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const ShowButton = () => {
        if(window.innerWidth <= 960) {
            setButton(false)
        } else {
            setButton(true)
        }
    };

    useEffect(() => {
        ShowButton();
    }, []);

    window.addEventListener('resize', ShowButton);

  return (
    <>
    <IconContext.Provider value={{color: '#fff'}}>
        <div className='navbar'>
            <div className='navbar-container'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    <img src={logo} />
                </Link>
                <div className='menu-icon' onClick={handleClick}>
                    {click ? <FaTimes /> : <FaBars />}
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item' onClick={closeMobileMenu}>
                        <Link to="/" className='nav-links'>
                            Beranda
                        </Link>
                    </li>
                    <li className='nav-item' onClick={closeMobileMenu}>
                        <Link to="/About" className='nav-links'>
                            Tentang Kami
                        </Link>
                    </li>
                    <li className='nav-item' onClick={closeMobileMenu}>
                        <Link to="/" className='nav-links'>
                            Produk
                        </Link>
                    </li>
                    <li className='nav-item' onClick={closeMobileMenu}>
                        <Link to="/" className='nav-links'>
                            SDG
                        </Link>
                    </li>
                    <li className='nav-item' onClick={closeMobileMenu}>
                        <Link to="/" className='nav-links'>
                            Karir
                        </Link>
                    </li>
                    <li className='nav-item' onClick={closeMobileMenu}>
                        <Link to="/" className='nav-links'>
                            Distribusi
                        </Link>
                    </li>
                    <li className='nav-btn'>
                        {button ? (
                            <Link to='/sign-up' className='btn-link'>
                                <Button buttonStyle='button--outline'>Daftar/Masuk</Button>
                            </Link>
                        ):(
                            <Link to='/sign-up' className='btn-link' onClick={closeMobileMenu}>
                                <Button buttonStyle='button--outline' buttonSize='btn--mobile'>Daftar/Masuk</Button>
                            </Link>
                        )}
                    </li>
                </ul>
            </div>
        </div>
        </IconContext.Provider>
    </>
  )
}

export default Navbar;