export const homeObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Kenali Perusahaan Kami',
    headline: 'Mengutamakan customer satisfaction',
    description: 'Kami memproduksi berbagai macam produk dan brand dengan bahan murni berkualitas.',
    buttonLabel: 'Pelajari',
    imgStart: '',
    img: './images/factory-svgrepo-com.svg',
    alt: 'Factory',
};

export const homeObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Kenali Brand Kami',
    headline: 'POLO & OASIS',
    description: 'Brand ternama yang telah hadir di pasar selama puluhan tahun, kini kami membawanya satu tingkat kedepan.',
    buttonLabel: 'Telusuri',
    imgStart: '',
    img: './images/poloasis.png',
    alt: 'Poloasis',
};

export const homeObjThree = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Pelajari Aktivitas Kami',
    headline: 'Mengatur pembuangan limbah',
    description: 'Kami menjaga pembuangan limbah dari hasil produksi untuk tidak membahayakan lingkungan.',
    buttonLabel: 'Baca Lagi',
    imgStart: '',
    img: './images/environment.svg',
    alt: 'Environment',
};

export const homeObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Bergabung dengan kami',
    headline: 'Menempuh karir bersama dengan IMTI',
    description: 'Ketahui syarat dan kriteria kerja yang sesuai dengan anda.',
    buttonLabel: 'Pelajari',
    imgStart: '',
    img: './images/resume.svg',
    alt: 'Resume',
};