import React from 'react'
import AboutSection from '../../AboutSection';
import { aboutObjFour, aboutObjOne, aboutObjThree, aboutObjTwo } from './Data';


function About() {
  return (
    <>
        <AboutSection {...aboutObjOne} />
        <AboutSection {...aboutObjTwo} />
        <AboutSection {...aboutObjThree} />
        <AboutSection {...aboutObjFour} />
    </>
  );
}

export default About;