import React from 'react';
import './Footer.css';
import { Button } from '../../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaLinkedin
} from 'react-icons/fa';
import logo from '../../../IMTI-1 copy.png';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Terima update promosi terbaru dari kami
        </p>
       <p className='footer-subscription-text'>
          Layanan bantuan hubungi layanan@poloasis.id atau 031-5150-3390
        </p> 
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Alamat email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Perusahaan</h2>
            <Link to='/sign-up'>Lowongan</Link>
            <Link to='/'>IR Relation</Link>
            <Link to='/'>Garansi</Link>
            <Link to='/'>Verifikasi</Link>
            <Link to='/'>Legal</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Hubungi Kami</h2>
            <Link to='/'>Kontak</Link>
            <Link to='/'>FAQ</Link>
            <Link to='/'>Media</Link>
            <Link to='/'>Kerjasama</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Relasi Bisnis</h2>
            <Link to='/'>Event/Acara</Link>
            <Link to='/'>Menjadi Distributor</Link>
            <Link to='/'>Menjadi Agen</Link>
            <Link to='/'>Promosi Online</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='/'>Instagram</Link>
            <Link to='/'>Facebook</Link>
            <Link to='/'>Youtube</Link>
            <Link to='/'>LinkedIn</Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
          <Link to='/' className='navbar-logo'>
                <img src={logo} />
          </Link>
          </div>
          <small className='website-rights'>IMTI © 2022</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='Instagram'
            >
              <FaInstagram />
            </Link>
            <Link
              className='social-icon-link'
              to={
                '//www.youtube.com/channel/UCsKsymTY_4BYR-wytLjex7A?view_as=subscriber'
              }
              target='_blank'
              aria-label='Youtube'
            >
              <FaYoutube />
            </Link>
            <Link
              className='social-icon-link'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;