export const aboutObjOne = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Kenali IMTI',
    headline: 'Manufakturer tangki air plastik dan resin',
    description: 'Berasal dari Surabaya, kami telah hadir di pasar sejak tahun 1999. Agar kami bisa lebih tanggap terhadap permintaan pasar, kami melakukan ekspansi dari CV. Imex Tama menjadi PT. Imex Tama Indonesia.',
    buttonLabel: 'Pelajari',
    imgStart: '',
    img: './images/factory.png',
    alt: 'Factory',
};

export const aboutObjTwo = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Kenapa IMTI',
    headline: 'Managemen operasional dan produksi standar internasional',
    description: 'Perusahaan kami telah mendapatkan sertifikat ISO 9001, menjamin kualitas managemen dan produk kami. Semua produk kami juga telah disahkan oleh SNI.',
    buttonLabel: 'Pelajari',
    imgStart: '',
    img: './images/certificate.png',
    alt: 'ISO',
};

export const aboutObjThree = {
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Keunggulan IMTI',
    headline: 'Prioritas tinggi untuk customer service dan after sales',
    description: 'Customer bisa menghubungi kami dengan mudah karena kami memastikan bahwa penanganan claim kami ramah dan profesional.',
    buttonLabel: 'Pelajari',
    imgStart: '',
    img: './images/worker.png',
    alt: 'Worker',
};

export const aboutObjFour = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Manajemen IMTI',
    headline: 'Supervisi tinggi untuk memastikan kualitas produk',
    description: 'Kami memiliki tata manajemen yang rapi untuk mengontrol kualitas dari setiap produk yang akan kami sebarkan di pasar.',
    buttonLabel: 'Pelajari',
    imgStart: '',
    img: './images/qc.png',
    alt: 'QC',
};